import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Col, Container, Row } from "react-bootstrap"

const CookiePolicy = () => (
  <Layout pageInfo={{ pageName: "CookiePolicy", pageTitle: "Cookie policy" }}>
    <Seo title="Cookie Policy"/>

    <section className="general-page" id="cookie-policy">
      <Container>

        <Row>
          <Col>
          </Col>
        </Row>

      </Container>
    </section>
  </Layout>
)

export default CookiePolicy
